<template>
  <div class="vg_wrapper">
    <el-form ref="mtrbForm" :model="mtrbForm" :rules="rules" label-width="120px" size="mini">
      <add-header @submit="submit('mtrbForm')" @cancel="cancel" :stateFlag="true" :formData="mtrbForm"> </add-header>
      <el-row>
        <el-col :md="16">
          <el-col :md="12" class="vg_mb_5">
            <el-form-item label="材料类别" prop="mtrb_type">
              <el-select v-model="mtrbForm.mtrb_type" filterable placeholder="请选择材料类别" @change="colorChange">
                <el-option v-for="item in mtrbTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">
            <el-form-item :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]" label="包材品类" prop="mtrb_pctype">
              <el-select v-model="mtrbForm.mtrb_pctype" filterable placeholder="请选择包材品类">
                <el-option v-for="item in pctypeList" :key="item.param1 + item.id" :label="item.param1" :value="item.param1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 3" :md="12" class="vg_mb_5">
            <el-form-item :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]" label="猫草种类" prop="catnip_type">
              <el-select v-model="mtrbForm.catnip_type" filterable placeholder="请选择" @change="changeCatipType">
                <el-option v-for="item in catGrassSpeciesList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 3" :md="12" class="vg_mb_5">
            <el-form-item :rules="[{ required: true, trigger: ['blur', 'change'], message: ' ' }]" label="英文名称" prop="mtrb_ename">
              <el-input v-model="mtrbForm.mtrb_ename" disabled placeholder="请填写" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">
            <el-form-item label="客户货号" prop="cust_catalog_number">
              <el-input
                v-model="mtrbForm.cust_catalog_number"
                maxlength="20"
                placeholder="请填写客户货号"
                show-word-limit
                @change="val => (mtrbForm.mtrb_name = val)"
                @input="val => (mtrbForm.cust_catalog_number = removeChinese(val))"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type !== 3" :md="12" class="vg_mb_5">
            <el-form-item label="中文品名" prop="mtrb_name">
              <el-input v-model="mtrbForm.mtrb_name" maxlength="100" placeholder="请填写中文品名" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">
            <el-form-item label="尺寸" prop="mtrb_size">
              <el-input v-model="mtrbForm.mtrb_size" placeholder="请填写尺寸" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12" class="vg_mb_5">
            <el-form-item label="客户简称" prop="cust_id">
              <el-select v-model="mtrbForm.cust_id" clearable filterable size="small">
                <el-option v-for="item in custList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="24" class="vg_mb_5">
            <el-form-item label="克重&材质" prop="mtrb_quality">
              <el-input v-model="mtrbForm.mtrb_quality" maxlength="100" placeholder="请填写克重&材质" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <!--<el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">-->
          <!--  <el-form-item label="专色号" prop="mtrb_color_num">-->
          <!--    <el-input v-model="mtrbForm.mtrb_color_num" maxlength="50" placeholder="请填写专色号" show-word-limit></el-input>-->
          <!--  </el-form-item>-->
          <!--</el-col>-->
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="24" class="vg_mb_5">
            <el-form-item label="印刷颜色&工艺" prop="mtrb_colour">
              <el-input v-model="mtrbForm.mtrb_colour" maxlength="100" placeholder="请填写颜色" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="24" class="vg_mb_5">
            <el-form-item label="产品描述" prop="mtrb_description">
              <el-input v-model="mtrbForm.mtrb_description" :rows="3" placeholder="请填写产品描述" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">
            <el-form-item label="版本号" prop="mtrb_version_no">
              <el-input v-model="mtrbForm.mtrb_version_no" maxlength="5" placeholder="请填写版本号" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 2" :md="12" class="vg_mb_5">
            <el-form-item label="单位" prop="mtrb_unit">
              <el-select v-model="mtrbForm.mtrb_unit" filterable placeholder="请选择材料单位">
                <el-option v-for="item in mtrbUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type === 1" :md="24" class="vg_mb_5">
            <el-form-item label="中文规格" prop="mtrb_spec">
              <el-input v-model="mtrbForm.mtrb_spec" placeholder="请填写中文规格" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12" class="vg_mb_5">
            <el-form-item label="供应商简称" prop="supp_id">
              <el-select v-model="mtrbForm.supp_id" filterable placeholder="请选择供应商简称">
                <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12" class="vg_mb_5">
            <el-form-item v-if="mtrbForm.mtrb_type === 3" label="单位" prop="mtrb_unit">
              <el-select v-model="mtrbForm.mtrb_unit" filterable placeholder="请选择材料单位">
                <el-option v-for="item in mtrbUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type !== 3" :md="12" class="vg_mb_5">
            <el-form-item label="采购单价" prop="mtrb_price">
              <el-input
                v-model="mtrbForm.mtrb_price"
                maxlength="9"
                placeholder="请填写采购价格"
                show-word-limit
                @blur="mtrbForm.mtrb_price = helper.calcPrice(mtrbForm.mtrb_price, 4, 10000)"
                @input="mtrbForm.mtrb_price = helper.keepTNum1(mtrbForm.mtrb_price)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type !== 3" :md="12" class="vg_mb_5">
            <el-form-item label="增值税率" prop="mtrb_inrate">
              <el-input
                v-model="mtrbForm.mtrb_inrate"
                maxlength="9"
                placeholder="请填写增值税率"
                show-word-limit
                @blur="mtrbForm.mtrb_inrate = helper.calcPrice(mtrbForm.mtrb_inrate, 4, 10000)"
                @input="mtrbForm.mtrb_inrate = helper.keepTNum1(mtrbForm.mtrb_inrate)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="mtrbForm.mtrb_type !== 3" :md="12" class="vg_mb_5">
            <el-form-item label="起订量" prop="mtrb_moq">
              <el-input
                v-model="mtrbForm.mtrb_moq"
                maxlength="10"
                placeholder="请填写起订量"
                show-word-limit
                @input="mtrbForm.mtrb_moq = helper.keepTNum1(mtrbForm.mtrb_moq)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="备注" prop="mtrb_remark">
              <el-input v-model="mtrbForm.mtrb_remark" :rows="4" maxlength="255" placeholder="请填写备注" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <el-form-item label="其他" prop="mtrb_other">
              <el-input maxlength="255" v-model="mtrbForm.mtrb_other" type="textarea" disabled :rows="3" show-word-limit placeholder="暂无其他"> </el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8">
          <el-form-item label="材料主图" prop="imge_id">
            <div class="vd_dis">
              <div>
                <el-upload
                  class="upload-demo"
                  drag
                  :action="uploadUrl"
                  :data="dataBody"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  ref="uploadMutiple"
                >
                  <imgPopover v-if="mtrbForm.imge_id" :imgeUrl="mtrbForm.imge_url" :imgeUrlO="mtrbForm.imge_urlO"> </imgPopover>
                  <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                </el-upload>
                <span>大小不能超过10MB</span>
              </div>
              <!--                            <div id="preview" class="vd_flex_right">-->
              <!--                              <el-link slot="trigger" class="vg_cursor" type="primary" @click="val => pasteImage({ ref: $refs[`uploadMutiple`] })">粘贴</el-link>-->
              <!--                              &lt;!&ndash;<span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>&ndash;&gt;-->
              <!--                              <el-tooltip class="vg_ml_8" content="清除图片" effect="dark" placement="bottom" style="margin-left: auto">-->
              <!--                                <el-button-->
              <!--                                  icon="el-icon-delete"-->
              <!--                                  type="text"-->
              <!--                                  @click="-->
              <!--                                    mtrbForm.imge_id = '';-->
              <!--                                    mtrbForm.imge_url = '';-->
              <!--                                    mtrbForm.imge_urlO = '';-->
              <!--                                  "-->
              <!--                                ></el-button>-->
              <!--                              </el-tooltip>-->
              <!--                            </div>-->
              <div id="preview" @paste="handlePaste" class="vd_flex_right">
                <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
                <el-tooltip class="vg_ml_8" content="清除图片" effect="dark" placement="bottom" style="margin-left: auto">
                  <el-button
                    icon="el-icon-delete"
                    type="text"
                    @click="
                      mtrbForm.imge_id = '';
                      mtrbForm.imge_url = '';
                      mtrbForm.imge_urlO = '';
                    "
                  ></el-button>
                </el-tooltip>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrbAPI } from '@api/modules/mtrb';
import { imgeAPI } from '@api/modules/imge';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import { settAPI } from '@/api/modules/sett';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';
import { keep4Decimal, removeChinese } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';
import { getCustomerInfo, pasteImage } from '@api/public';
import { custAPI } from '@api/modules/cust';

export default {
  name: 'MtrbAddMain',
  components: {
    addHeader,
    inputUser,
    imgPopover
  },
  created() {
    this.initData();
  },
  mounted() {
    let { syncMtrbData } = this.$route.params;
    if (syncMtrbData) this.mtrbForm = syncMtrbData;
  },
  data() {
    return {
      catGrassSpeciesList: [
        { value: 'A类猫草', label: 'A类猫草' },
        { value: 'B类猫草', label: 'B类猫草' }
      ],
      rules: {
        mtrb_name: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrb_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrb_price: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_unit: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrb_inrate: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_gweight: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_size: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_colour: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_quality: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrb_technology: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_catalog_number: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_id: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      mtrbForm: {
        mtrb_no: null,
        mtrb_id: null,
        mtrb_name: null,
        mtrb_type: 2, //新增默认包材
        mtrb_spec: null,
        mtrb_price: null,
        supp_id: null,
        mtrb_unit: '个',
        mtrb_inrate: '',
        mtrb_moq: null,
        mtrb_remark: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mtrb_colour: '四色'
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800
      },
      mtrbUnitList: [],
      suppList: [],
      custList: [],
      mtrbTypeList: [
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' },
        { id: 3, label: '猫草' }
      ],
      pctypeList: []
    };
  },
  methods: {
    pasteImage,
    removeChinese,
    keep4Decimal,
    async initData() {
      this.getMtrbRankList();
      this.getSupp();
      this.getSettList();
      this.getPctypeList();
      // let { dept_id, stff_id } = this.$cookies.get('userInfo');
      this.custList = await getCustomerInfo({ status: 2, type: 'abbr' });
    },
    //默认四色
    colorChange(val) {
      if (val === 2) {
        this.mtrbForm.mtrb_colour = '四色';
      }
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let mtrbcopy = JSON.parse(JSON.stringify(this.mtrbForm));
      let mtrbForm = Object.assign(mtrbcopy, staffForm);
      mtrbForm.personal = this.$cookies.get('userInfo').personal;
      post(mtrbAPI.addMtrb, mtrbForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const props = this.$route.query;
            this.$EventBus.$emit('close_current_tags');
            this.jump('/mtrb_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: props.perm_id,
                  form_id: res.data.data.form_id
                })
              )
            });
            this.resetForm('mtrbForm');
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mtrbForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    getSettList() {
      get(settAPI.getSettByKey, { sett_key: 'mtrb_inrate' })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrbForm.mtrb_inrate = this.helper.haveFour(res.data.data.form.sett_value);
            // let settList = res.data.data.list;
            // for (let i = 0; i < settList.length; i++) {
            //   if (settList[i].sett_key === 'mtrb_inrate') {
            //
            //   }
            // }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取稿件等级
    getMtrbRankList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrbUnitList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 获取稿件等级
    getPctypeList() {
      get(optnAPI.getOptnByPermIdV1, { perm_id: 10024 })
        .then(res => {
          if (res.data.code === 0) {
            this.pctypeList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrbForm.imge_id = res.data.imge_id;
        this.mtrbForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
        this.mtrbForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false;
      }
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地或请打开图片再复制');
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        this.$message.error('粘贴内容非图片');
        return;
      }
      this.$refs.uploadMutiple.handleStart(file); // 将粘贴过来的图片加入预上传队列
      this.$refs.uploadMutiple.submit(); // 提交图片上传队列
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeCatipType(val) {
      if (val === 'A类猫草') this.mtrbForm.mtrb_ename = 'teabag cut catnip';
      if (val === 'B类猫草') this.mtrbForm.mtrb_ename = 'Leaf and flower';
    },
    changeMtrbType(val) {
      if (val === 3) {
        this.mtrbForm.mtrb_unit = '克';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

::v-deep .el-upload--text {
  height: 300px;
  width: 300px;
}

::v-deep .el-upload-dragger {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_flex_right {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}
</style>
